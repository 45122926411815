import React from "react";

export default function FaqPage() {

  return (
      <div className="row">
        <div className="col-12">
          <h1 className="cover-heading">Frequently Asked Questions</h1>

          <div className="faq mt-4">
            <div className="faq-item mt-4">
              <h3>Why is <em>Connect</em> required to fetch the data it provides?</h3>

              <p>
                While some data provided by <em>Connect</em> might be accessible from other sources and could be fetched
                directly by nzyme, we have no control over those APIs, potential changes to them, or rate limits.
                Additionally, certain sources may require you to obtain API keys for each service individually.
              </p>

              <p>
                Furthermore, some data, such as alert definitions, must be readily available and cannot wait for the
                next packaged nzyme release.
              </p>
            </div>

            <div className="faq-item mt-4">
              <h3>How is data exchanged between my nzyme setup and <em>Connect</em>?</h3>

              <p>
                Once you connect your nzyme setup to <em>Connect</em>, a bidirectional data exchange begins: Your setup
                transmits health and status information to <em>Connect</em> every minute, and <em>Connect</em> responds
                with up-to-date data (e.g., GeoIP, MAC OUIs, etc.) during an hourly poll.
              </p>

              <p>
                All data is cached locally, ensuring that no internet connection is required to process incoming tap
                data, and no significant latency is introduced.
              </p>
            </div>

            <div className="faq-item mt-4">
              <h3>What data is sent by my nzyme setup?</h3>

              <p>
                The health and status information sent to <em>Connect</em> includes only non-sensitive data. It
                exclusively transmits internal health metrics and status updates. You can review the code yourself—start
                with the <a href="https://github.com/nzymedefense/nzyme/blob/master/src/main/java/app/nzyme/core/connect/reports/ConnectStatusReport.java" target="_blank">
                ConnectStatusReport</a> file.
              </p>
            </div>

            <div className="faq-item mt-4">
              <h3>How do I make this work in air-gapped networks?</h3>

              <p>
                We can assist you in making <em>Connect</em> work in air-gapped networks without internet access.
                Please <a href="https://go.nzyme.org/contact" target="_blank">contact us</a> to learn more.
              </p>
            </div>
          </div>

          <p className="mt-5">
            <a href="https://go.nzyme.org/contact" target="_blank">Contact us</a> if you have any other
            questions. We are happy to help!
          </p>

        </div>
      </div>)

}