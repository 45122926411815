import React from "react";
import { useEffect } from 'react';

const useBreadcrumbs = (breadcrumbs, items) => {
  useEffect(() => {
    breadcrumbs.set(
        <React.Fragment>
          {items}
        </React.Fragment>
    );

    return () => breadcrumbs.set(null);
  }, []);
};

export default useBreadcrumbs;