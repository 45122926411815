import React from "react";

export default function HealthLevel(props) {

  const indicator = props.indicator;

  if (!indicator.is_active) {
    return <td className="bg-muted health-indicator-status">
      <i className="bx bx-info-circle" title="Indicator is disabled on this cluster."/> Disabled
    </td>
  }

  switch (indicator.level) {
    case "GREEN":
      return <td className="bg-success health-indicator-status">
        <i className="bx bx-check-circle" title="Indicator is healthy."/> Healthy
      </td>;
    case "ORANGE":
      return <td className="bg-warning health-indicator-status">
        <i className="bx bx-error" title="Indicator is in WARNED state."/> Warning
      </td>;
    case "RED":
      return <td className="bg-danger health-indicator-status">
        <i className="bx bx-error" title="Indicator is in ERROR state."/> Error / Critical
      </td>;  }

}