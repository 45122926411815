import useApiClient from "../lib/APIClient";

const useClusterDataService = () => {

  const api = useApiClient();

  const getBluetoothSummary = (clusterUuid, setSummary) => {
    api.get(`/mgmt/clusters/${clusterUuid}/data/bluetooth`, {}, (response) => setSummary(response.data))
  }

  const getOuiSummary = (clusterUuid, setSummary) => {
    api.get(`/mgmt/clusters/${clusterUuid}/data/oui`, {}, (response) => setSummary(response.data))
  }

  const getGeoIpSummary = (clusterUuid, setSummary) => {
    api.get(`/mgmt/clusters/${clusterUuid}/data/geoip`, {}, (response) => setSummary(response.data))
  }

  const toggleDataType = (clusterUuid, dataType, newStatus, onSuccess) => {
    api.post(`/mgmt/clusters/${clusterUuid}/data/${dataType}/${newStatus ? "enable" : "disable"}`, {}, onSuccess);
  }

  return {
    getBluetoothSummary, getGeoIpSummary, getOuiSummary, toggleDataType
  }

}

export default useClusterDataService;