import React from "react";

export default function HowtoPage() {

  return (
      <div className="row">
        <div className="col-12">
          <h1 className="cover-heading">How To Use nzyme Connect</h1>

          <p>
            Setting up your nzyme Connect integration is easy:
          </p>

          <ol>
            <li>Sign up for your Connect account and log in.</li>
            <li>Create a cluster in the Connect UI and copy the API key that was automatically generated for it.</li>
            <li>As super administrator, navigate to the <i>Connect</i> page in your nzyme Web Interface and enter
              your API key.</li>
            <li>Your nzyme infrastructure will automatically connect and exchange data.</li>
          </ol>

        </div>
      </div>)

}