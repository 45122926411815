import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import useClusterService from "../../../../services/ClusterService";
import {BreadcrumbContext, HeadlineContext, SidebarContext} from "../../../LoggedInApp";
import useBreadcrumbs from "../../../ui/useBreadcrumbs";
import ClusterDetailsSidebar from "../ClusterDetailsSidebar";
import BackToClustersLink from "../../../ui/BackToClustersLink";
import ClusterHeadline from "../../../ui/ClusterHeadline";
import HealthLevel from "./HealthLevel";
import PrimaryHelp from "../../../ui/PrimaryHelp";
import NeverReportedPage from "../NeverReportedPage";
import LoadingDialog from "../../../misc/LoadingDialog";

export default function ClusterHealthPage() {

  const { uuid } = useParams();

  const clusterService = useClusterService();
  const headline = useContext(HeadlineContext);
  const sidebar = useContext(SidebarContext);

  const [cluster, setCluster] = useState(null);

  useBreadcrumbs(useContext(BreadcrumbContext), <BackToClustersLink />)

  useEffect(() => {
    sidebar.set(<ClusterDetailsSidebar cluster={null} clusterId={uuid} />);
    setCluster(null);
    clusterService.findOneCluster(uuid, setCluster);
    return () => {
      sidebar.set(null);
    };
  }, [uuid]);

  useEffect(() => {
    if (cluster !== null) {
      headline.set(<ClusterHeadline cluster={cluster} />)
      sidebar.set(<ClusterDetailsSidebar cluster={cluster} clusterId={uuid} />);
    }

    return () => {
      headline.set(null);
      sidebar.set(null);
    };
  }, [cluster]);

  const indicatorDescription = (indicatorId) => {
    switch (indicatorId) {
      case "crypto_sync":
        return "Ensures all nodes share the same PGP key."
      case "db_clock":
        return "Ensures that the database server clock is synchronized with reference world time."
      case "message_bus_message_failure":
        return "Ensures all messages in the local message bus are successfully processed."
      case "message_bus_message_stuck":
        return "Ensures that no messages in the local message bus remain unprocessed. (claimed by a receiver but not processed)"
      case "node_clock":
        return "Ensures that all nzyme nodes have a local system clock that is synchronized with reference world time."
      case "node_offline":
        return "Ensures that all nzyme nodes are running."
      case "tap_buffer":
        return "Ensures that no tap is running out of buffer space."
      case "tap_clock":
        return "Ensures that all taps have a local system clock that is synchronized with reference world time."
      case "tap_drop":
        return "Ensures that no tap is dropping packets on the capture handle."
      case "tap_error":
        return "Ensures that no tap is throwing processing errors."
      case "tap_offline":
        return "Ensures that all taps are running."
      case "tap_tpx":
        return "Ensures that all taps are reporting throughput / are recording traffic."
      case "tasks_queue_task_failure":
        return "Ensures that all cluster tasks were executed successfully."
      case "tasks_queue_task_stuck":
        return "Ensures that no cluster tasks remain unprocessed. (claimed by a node to work on but not executed)"
      case "tls_exp":
        return "Ensures that no node is using a TLS certificate that is about to expire or has expired."
      default:
        return "No description available for indicator " + indicatorId;
    }
  }

  if (!cluster) {
    return <LoadingDialog />
  }

  if (cluster.status === "INACTIVE") {
    return <NeverReportedPage />
  }

  return (
      <div className="row">
        <div className="col col-12">
          <div className="section">
            <h2>Cluster Health</h2>

            <PrimaryHelp>
              An nzyme cluster continuously monitors its own health and can alert you if any issues are detected
              during the self-test procedures. The <em>Health</em> page in the <em>System</em> area of your nzyme
              web interface will display problem resolution procedures in case of any issues.
            </PrimaryHelp>

            <table className="table table-hover">
              <thead>
              <tr>
                <th>Status</th>
                <th>Name</th>
                <th>Description</th>
              </tr>
              </thead>
              <tbody>
              {cluster.health_indicators.sort((a, b) => a.name.localeCompare(b.name)).map((hi, i) => {
                return (
                  <tr key={i}>
                    <HealthLevel indicator={hi} />
                    <td>{hi.name}</td>
                    <td>{indicatorDescription(hi.id)}</td>
                  </tr>
                )
              })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
  )

}