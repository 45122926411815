import useApiClient from "../lib/APIClient";

const useUserService = () => {

  const api = useApiClient();

  const findOwnUser = (setUser) => {
    api.get("/auth/users/self", {}, (response) => setUser(response.data))
  }

  const changeOwnPassword = (password, onSuccess) => {
    api.put("/auth/users/self/password", {password: password}, onSuccess);
  }

  const changeOwnProfile = (fields, onSuccess) => {
    api.put("/auth/users/self/profile", fields, onSuccess);
  }

  const deleteOwnUser = (onSuccess) => {
    api.del("/auth/users/self", onSuccess);
  }

  return {
    findOwnUser, changeOwnPassword, changeOwnProfile, deleteOwnUser
  }

}

export default useUserService;