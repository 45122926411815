import React from "react";
import AlertInfo from "../../ui/AlertInfo";
import ClusterRow from "./ClusterRow";

export default function ClustersTable(props) {

  const clusters = props.clusters;

  if (clusters.length === 0) {
    return <div className="mb-3"><AlertInfo>No clusters configured yet.</AlertInfo></div>
  }

  return (
      <div className="table-responsive">
        <table className="table clusters">
          <thead>
          <tr>
            <th style={{whiteSpace: "nowrap"}}>Cluster</th>
            <th style={{width: "100%"}}>Throughput</th>
            <th style={{whiteSpace: "nowrap"}}>&nbsp;</th>
            <th style={{width: 75}}>Health</th>
            <th style={{width: 25}}>&nbsp;</th>
          </tr>
          </thead>
          <tbody>
          {clusters.map((c, i) => {
            return <ClusterRow key={i} cluster={c}/>
          })}
          </tbody>
        </table>
      </div>
  )

}