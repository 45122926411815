import React from "react";

export default function PrimaryHelp(props) {

  return (
      <p>
        {props.children}
      </p>
  )

}