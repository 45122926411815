import {useNavigate, useParams} from "react-router-dom";
import useClusterService from "../../../../services/ClusterService";
import React, {useContext, useEffect, useState} from "react";
import {BreadcrumbContext, HeadlineContext, SidebarContext} from "../../../LoggedInApp";
import useBreadcrumbs from "../../../ui/useBreadcrumbs";
import BackToClustersLink from "../../../ui/BackToClustersLink";
import ClusterDetailsSidebar from "../ClusterDetailsSidebar";
import ClusterHeadline from "../../../ui/ClusterHeadline";
import {toast} from "react-toastify";
import ClusterForm from "../mgmt/ClusterForm";
import {DateTime} from "luxon";
import {LoggedInRoutes} from "../../../../LoggedInRoutes";
import PrimaryHelp from "../../../ui/PrimaryHelp";
import LoadingDialog from "../../../misc/LoadingDialog";

export default function ClusterSettingsPage() {

  const { uuid } = useParams();

  const clusterService = useClusterService();
  const navigate = useNavigate();
  const headline = useContext(HeadlineContext);
  const sidebar = useContext(SidebarContext);

  const [cluster, setCluster] = useState(null);
  const [revision, setRevision] = useState(DateTime.now());

  const [deleteConfirmed, setDeleteConfirmed] = useState(false);

  useBreadcrumbs(useContext(BreadcrumbContext), <BackToClustersLink />)

  useEffect(() => {
    sidebar.set(<ClusterDetailsSidebar cluster={null} clusterId={uuid} />);
    setCluster(null);
    clusterService.findOneCluster(uuid, setCluster);
    return () => {
      sidebar.set(null);
    };
  }, [uuid]);

  useEffect(() => {
    if (cluster !== null) {
      headline.set(<ClusterHeadline cluster={cluster} />)
      sidebar.set(<ClusterDetailsSidebar cluster={cluster} clusterId={uuid} />);
    }

    return () => {
      headline.set(null);
      sidebar.set(null);
    };
  }, [cluster, revision]);

  const onSubmitUpdateName = (name, onComplete) => {
    clusterService.editCluster(
        uuid, name, () => {
          toast.success("Cluster updated.");
          onComplete();
          clusterService.findOneCluster(uuid, setCluster);
          setRevision(DateTime.now());
        }, () => {
          toast.error("Could not update cluster. Please try again.");
          onComplete();
        }
    )
  }

  const onClickDeleteCluster = () => {
    if (!confirm("Really delete cluster? This cannot be undone.")) {
      return;
    }

    clusterService.deleteCluster(cluster.uuid, () => {
      toast.success("Cluster deleted.");
      navigate(LoggedInRoutes.CLUSTERS.INDEX);
    })
  }

  if (!cluster) {
    return <LoadingDialog />
  }

  return (
      <React.Fragment>
        <div className="row">
          <div className="col col-12">
            <div className="section">
              <h2>Cluster Settings</h2>

              <ClusterForm buttonText="Change Name" name={cluster.name} onSubmit={onSubmitUpdateName}/>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col col-12">
            <div className="section">
              <h2>Delete Cluster</h2>

              <PrimaryHelp>
                You can delete a cluster in <em>Connect</em> at any time. Remember to disable
                the <em>Connect</em> connection in your nzyme cluster settings after doing it.
                No services provided by <em>Connect</em> will be available to the cluster
                until you create a new cluster and re-connect it.
              </PrimaryHelp>

              <div className="row">
                <div className="col-8">
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="confirmed"
                           checked={deleteConfirmed} onChange={() => setDeleteConfirmed(!deleteConfirmed)}/>

                    <label className="form-check-label" htmlFor="confirmed">
                      I understand that I cannot undo deleting a cluster and <strong>all related data will
                      be lost</strong>.
                    </label>
                  </div>
                </div>
              </div>

              <button className="btn btn-danger mt-3" onClick={onClickDeleteCluster} disabled={!deleteConfirmed}>
                Delete Cluster
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
  )

}