import React from "react";
import AlertSuccess from "../../../ui/AlertSuccess";
import AlertWarning from "../../../ui/AlertWarning";
import useClusterDataService from "../../../../services/ClusterDataService";

export default function DataStatus(props) {

  const cluster = props.cluster;
  const dataType = props.dataType;
  const status = props.status;
  const onChange = props.onChange;

  const clusterDataService = useClusterDataService();

  const toggle = (status) => {
    if (status) {
      // Enable.
      if(!confirm("Really enable this data type?")) {
        return;
      }
    } else {
      // Disable.
      if(!confirm("Really disable this data type?")) {
        return;
      }
    }

    clusterDataService.toggleDataType(cluster.uuid, dataType, status, onChange);
  }

  if (status) {
    return (
        <AlertSuccess hideIcon={true}>
          <div className="flex-container">
            <div>
              <i className="bx bx-check-circle alert-icon" />{' '}
              This data type is <strong>enabled</strong> and supplied to this cluster.
            </div>

            <button className="btn btn-secondary" onClick={() => toggle(false)}>Disable Data Type</button>
          </div>
        </AlertSuccess>
    )
  } else {
    return (
        <AlertWarning hideIcon={true}>
          <div className="flex-container">
            <div>
              <i className="bx bx-error alert-icon" />{' '}
              This data type is <strong>disabled</strong> and <strong>not</strong> supplied to this cluster.
            </div>

            <button className="btn btn-success" onClick={() => toggle(true)}>Enable Data Type</button>
          </div>
        </AlertWarning>
    )
  }

}