import React from "react";
import {NavLink} from "react-router-dom";
import {LoggedInRoutes} from "../../../LoggedInRoutes";
import ClusterHealthStatus from "./ui/ClusterHealthStatus";

export default function ClusterDetailsSidebar(props) {

  const cluster = props.cluster;
  const clusterId = props.clusterId;

  const CustomNavLink = ({ to, children }) => (
      <NavLink
          to={to}
          className={({ isActive }) => isActive ? 'active' : ''}
          style={{ textDecoration: 'none' }}
      >
        {({ isActive }) => (
            <li className={isActive ? 'active' : ''}>
              {children}
            </li>
        )}
      </NavLink>
  );

  return (
      <React.Fragment>
        <ul className="mt-2 mb-sm-0 mb-md-4">
          <CustomNavLink to={LoggedInRoutes.CLUSTERS.SHOW(clusterId)}>
            <i className="bx bx-home sidebar-icon" /> Overview
          </CustomNavLink>
        </ul>

        <ul className="mb-sm-0 mb-md-4">
          <CustomNavLink to={LoggedInRoutes.CLUSTERS.NODES(clusterId)}>
            <i className="bx bx-hive sidebar-icon"/> Nodes
          </CustomNavLink>
          <CustomNavLink to={LoggedInRoutes.CLUSTERS.TAPS(clusterId)}>
            <i className="bx bx-server sidebar-icon"/> Taps
          </CustomNavLink>
          <CustomNavLink to={LoggedInRoutes.CLUSTERS.HEALTH(clusterId)}>
            <i className="bx bx-heart sidebar-icon"/> Health {cluster && <ClusterHealthStatus cluster={cluster}/>}
          </CustomNavLink>
          <CustomNavLink to={LoggedInRoutes.CLUSTERS.SUPPORT(clusterId)}>
            <i className="bx bx-support sidebar-icon"/> Support
          </CustomNavLink>
        </ul>

        <ul className="mb-sm-0 mb-md-4">
          <CustomNavLink to={LoggedInRoutes.CLUSTERS.DATA.GEOIP(clusterId)}>
            <i className="bx bx-globe sidebar-icon" /> GeoIP
          </CustomNavLink>
          <CustomNavLink to={LoggedInRoutes.CLUSTERS.DATA.OUIS(clusterId)}>
            <i className="bx bx-data sidebar-icon" /> OUIs
          </CustomNavLink>
          <CustomNavLink to={LoggedInRoutes.CLUSTERS.DATA.BLUETOOTH(clusterId)}>
            <i className="bx bx-bluetooth sidebar-icon" /> Bluetooth
          </CustomNavLink>
        </ul>

        <ul>
          <CustomNavLink to={LoggedInRoutes.CLUSTERS.APIKEY(clusterId)}>
            <i className="bx bx-key sidebar-icon" /> API Key
          </CustomNavLink>
          <CustomNavLink to={LoggedInRoutes.CLUSTERS.SETTINGS.INDEX(clusterId)}>
            <i className="bx bx-cog sidebar-icon" /> Settings
          </CustomNavLink>
        </ul>
     </React.Fragment>
  )

}