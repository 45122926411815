import React from "react";

export default function FeaturesPage() {

  return (
      <div className="row">
        <div className="col-12">
          <h1 className="cover-heading">Features</h1>

          <p>
            This initial version of Connect offers a limited yet significant selection of features to enhance your
            nzyme setup, including:
          </p>

          <h2>Metadata Database Access</h2>

          <p>Your nzyme setup will pull metadata databases from <em>Connect</em> to enrich the data it collected.</p>

          <ul>
            <li>GeoIP data (provided by <a href="https://ipinfo.io/products/free-ip-database">IPinfo.io</a>)</li>
            <li>MAC Address OUI/Vendor information</li>
            <li>Bluetooth SIG assigned numbers information</li>
          </ul>

          <p>
            In the future, Connect APIs will support additional data types, such as alert definitions and fingerprints.
            We recommend integrating your nzyme setup as soon as possible to take full advantage of these upcoming
            features.
          </p>

          <h2>Monitoring</h2>

          <p>You can add as many nzyme clusters as you wish and <em>Connect</em> will keep an eye on local metrics
          as well as health indicators for you. The next release of <em>Connect</em> will let you subscribe
          to alerts and be notified in case something should go wrong.</p>

          <h2 className="mt-5">Offline Data Access</h2>

          <p>Please <a href="https://go.nzyme.org/contact">contact us</a> if you need offline access to the data
            provided by nzyme Connect.</p>
        </div>
      </div>
  )

}