import React from "react";

export default function AuthenticationError() {

  return (
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-12">
            <h1>Error.</h1>

            <p>
              We are having trouble with our authentication service. Please try again later
              or <a href="https://go.nzyme.org/contact">Contact Us</a> if this error persists.
            </p>
          </div>
        </div>
      </div>
  )

}