import React, {useContext} from "react";
import {LoggedInRoutes} from "../../../../LoggedInRoutes";
import {useNavigate} from "react-router-dom";
import ClusterForm from "./ClusterForm";
import {toast} from "react-toastify";
import useClusterService from "../../../../services/ClusterService";
import {BreadcrumbContext, HeadlineContext} from "../../../LoggedInApp";
import useBreadcrumbs from "../../../ui/useBreadcrumbs";
import useHeadline from "../../../ui/useHeadline";
import BackToClustersLink from "../../../ui/BackToClustersLink";

export default function CreateClusterPage() {

  const navigate = useNavigate();
  const clusterService = useClusterService();

  useBreadcrumbs(useContext(BreadcrumbContext), <BackToClustersLink />)

  useHeadline(useContext(HeadlineContext), <h1>Create Cluster</h1>);

  const onSubmit = (name, onComplete) => {
    clusterService.createCluster(
        name, () => {
          toast.success("Cluster created.");
          onComplete();
          navigate(LoggedInRoutes.CLUSTERS.INDEX);
        }, () => {
          toast.error("Could not create cluster. Please try again.");
          onComplete();
        }
    )
  }

  return (
      <div className="row">
        <div className="col col-12 col-md-6">
          <div className="section no-border">
            <ClusterForm buttonText="Create Cluster" onSubmit={onSubmit} />
          </div>
        </div>
      </div>
  )

}