import React from "react";

export default function ButtonSubmittingText() {

  return (
      <React.Fragment>
        <i className="bx bx-loader-alt bx-spin bx-flip-horizontal button-submitting-icon" /> Please wait ...
      </React.Fragment>
  )

}