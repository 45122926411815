import React, {useEffect, useState} from "react";
import Throughput from "../../ui/Throughput";

export default function CurrentClusterThroughput(props) {

  const cluster = props.cluster;

  const [tpx, setTpx] = useState(null);

  useEffect(() => {
    const sorted = cluster.tap_throughput.sort((a, b) => new Date(b.bucket) - new Date(a.bucket));
    setTpx(sorted[0].value);
  }, [cluster]);

  if (!tpx) {
    return null;
  }

  return <span className="cluster-throughput"><Throughput throughput={tpx} /></span>

}