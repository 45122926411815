import React from 'react';

export default function ErrorLoggedOutPage() {

  return (
      <div className="row">
        <div className="col-12">
          <h1 className="cover-heading">Error</h1>

          <p>
            <strong>Something went wrong.</strong>{' '}
            Please <a href="https://go.nzyme.org/contact" target="_blank">Contact us</a> if this error does not
            resolve itself.
          </p>
        </div>
      </div>
  )

}