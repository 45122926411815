import React, {useContext, useEffect} from "react";
import {BreadcrumbContext, HeadlineContext} from "../LoggedInApp";
import useBreadcrumbs from "../ui/useBreadcrumbs";
import BackToClustersLink from "../ui/BackToClustersLink";
import AlertInfo from "../ui/AlertInfo";

export default function NotFoundPage() {

  const headline = useContext(HeadlineContext);

  useBreadcrumbs(useContext(BreadcrumbContext), <BackToClustersLink />)

  useEffect(() => {
    headline.set(<h2>404</h2>)
  }, []);

  return (
      <div className="row">
        <div className="col-12">
          <div className="section">
            <AlertInfo>We did not find the page you requested.</AlertInfo>
          </div>
        </div>
      </div>
  )

}