import React from 'react';

export default function NotFoundLoggedOutPage() {

  return (
      <div className="row">
        <div className="col-12">
          <h1 className="cover-heading">404 - Page Not Found</h1>

          <p>
            We did not find the page you requested.
            Please <a href="https://go.nzyme.org/contact" target="_blank">Contact us</a> if you believe this is an
            error.
          </p>
        </div>
      </div>
  )

}