export const LoggedOutRoutes = {
  HOME: "/",
  FEATURES: "/features",
  FAQ: "/faq",
  HOWTO: "/howto",
  PRIVACY_POLICY: "/privacy-policy",
  COOKIE_POLICY: "/cookie-policy",
  TERMS_CONDITIONS: "/terms-conditions",
  ACCEPTABLE_USE: "/acceptable-use",
  ERROR: "/error"
}