import useApiClient from "../lib/APIClient";

const useClusterService = () => {

  const api = useApiClient();

  const findAllClusters = (setClusters) => {
    api.get("/mgmt/clusters", {}, (response) => setClusters(response.data))
  }

  const findOneCluster = (uuid, setCluster) => {
    api.get(`/mgmt/clusters/${uuid}`, {}, (response) => setCluster(response.data))
  }

  const findNodesOfCluster = (uuid, setNodes) => {
    api.get(`/mgmt/clusters/${uuid}/nodes`, {}, (response) => setNodes(response.data))
  }

  const findTapsOfCluster = (uuid, setTaps) => {
    api.get(`/mgmt/clusters/${uuid}/taps`, {}, (response) => setTaps(response.data))
  }

  const createCluster = (name, onSuccess, onFailure) => {
    api.post("/mgmt/clusters", {name: name}, onSuccess, onFailure)
  }

  const editCluster = (uuid, name, onSuccess, onFailure) => {
    api.put(`/mgmt/clusters/${uuid}`, {name: name}, onSuccess, onFailure)
  }

  const deleteCluster = (uuid, onSuccess) => {
    api.del(`/mgmt/clusters/${uuid}`, onSuccess)
  }

  const cycleClusterApiKey = (uuid, onSuccess) => {
    api.post(`/mgmt/clusters/${uuid}/apikey/cycle`, {}, onSuccess)
  }

  return {
    findAllClusters,
    findOneCluster,
    findNodesOfCluster,
    findTapsOfCluster,
    createCluster,
    editCluster,
    deleteCluster,
    cycleClusterApiKey
  }

}

export default useClusterService;