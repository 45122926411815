import React from "react";
import { useEffect } from 'react';

const useActionButtons = (actionButtons, items) => {

  useEffect(() => {
    actionButtons.set(
        <React.Fragment>
          {items}
        </React.Fragment>
    );

    return () => actionButtons.set(null);
  }, []);
};

export default useActionButtons;