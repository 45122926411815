import React from "react";
import numeral from "numeral";

export default function CriticalLogCount(props) {

  const count = props.count;

  if (count === null || count === 0) {
    return <span>{numeral(count).format("0,0")}/min</span>
  } else {
    return <span className="color-warning">
      <i className="bx bx-error" style={{position: "relative", top: 1}}/> {numeral(count).format("0,0")}/min
    </span>
  }

}