import React, {useContext, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import useClusterService from "../../../../../services/ClusterService";
import {BreadcrumbContext, HeadlineContext, SidebarContext} from "../../../../LoggedInApp";
import useBreadcrumbs from "../../../../ui/useBreadcrumbs";
import BackToClustersLink from "../../../../ui/BackToClustersLink";
import ClusterDetailsSidebar from "../../ClusterDetailsSidebar";
import ClusterHeadline from "../../../../ui/ClusterHeadline";
import PrimaryHelp from "../../../../ui/PrimaryHelp";
import useClusterDataService from "../../../../../services/ClusterDataService";

import numeral from "numeral";
import DataStatus from "../DataStatus";
import {DateTime} from "luxon";
import LoadingDialog from "../../../../misc/LoadingDialog";

export default function GeoIpPage() {

  const { uuid } = useParams();

  const clusterService = useClusterService();
  const clusterDataService = useClusterDataService();

  const headline = useContext(HeadlineContext);
  const sidebar = useContext(SidebarContext);

  const [cluster, setCluster] = useState(null);
  const [summary, setSummary] = useState(null);

  const [revision, setRevision] = useState(DateTime.now());

  useBreadcrumbs(useContext(BreadcrumbContext), <BackToClustersLink />)

  useEffect(() => {
    sidebar.set(<ClusterDetailsSidebar cluster={null} clusterId={uuid} />);
    setCluster(null);
    clusterService.findOneCluster(uuid, setCluster);
    return () => {
      sidebar.set(null);
    };
  }, [uuid]);

  useEffect(() => {
    if (cluster !== null) {
      headline.set(<ClusterHeadline cluster={cluster} />)
      sidebar.set(<ClusterDetailsSidebar cluster={cluster} clusterId={uuid} />);

      setSummary(null);
      clusterDataService.getGeoIpSummary(cluster.uuid, setSummary);
    }

    return () => {
      headline.set(null);
      sidebar.set(null);
      setSummary(null);
    };
  }, [cluster, revision]);

  const onChange = () => {
    setRevision(DateTime.now());
  }

  if (!cluster || !summary) {
    return <LoadingDialog />
  }

  return (
      <React.Fragment>
        <div className="row">
          <div className="col col-12">
            <div className="section">
              <h2>GeoIP</h2>

              <PrimaryHelp>
                The <em>Connect</em> service can supply GeoIP information to various nzyme subsystems that work with
                IPv4 and IPv6 addresses. This GeoIP data offers geographical, ASN, and other meta-information about
                IP addresses.
              </PrimaryHelp>

              <DataStatus dataType="geoip" cluster={cluster} status={summary.is_enabled} onChange={onChange}/>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col col-12">
            <div className="section">
              <h3>Data Provider</h3>
              <select className="form-select form-select-lg" disabled={true}>
                <option>IPinfo Free Country IP/ASN</option>
              </select>

              <p className="mt-2 mb-0">
                Currently, <em>Connect</em> only supports
                the <a href="https://ipinfo.io" target="_blank" rel="noreferrer">IPinfo.io</a> free GeoIp data as
                provider.
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col col-12">
            <div className="section">
              <h3>Provided Data</h3>

              <table className="table table-hover">
                <thead>
                <tr>
                  <th>Data Type</th>
                  <th>Size</th>
                  <th>Description</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>IPv4/IPv6 to Country and ASN</td>
                  <td>{numeral(summary.data.size).format("0,0b")}</td>
                  <td>Used to enrich IP addresses with country and ASN information where possible.</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </React.Fragment>
)

}