import React, {useContext, useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import useClusterService from "../../../../services/ClusterService";
import useBreadcrumbs from "../../../ui/useBreadcrumbs";
import {BreadcrumbContext, HeadlineContext, SidebarContext} from "../../../LoggedInApp";
import ClusterDetailsSidebar from "../ClusterDetailsSidebar";
import ClusterTapThroughputChart from "../ClusterTapThroughputChart";
import BackToClustersLink from "../../../ui/BackToClustersLink";
import ClusterHeadline from "../../../ui/ClusterHeadline";
import PrimaryHelp from "../../../ui/PrimaryHelp";
import LoadingDialog from "../../../misc/LoadingDialog";

export default function ClusterOverviewPage() {

  const { uuid } = useParams()
  const clusterService = useClusterService();
  const headline = useContext(HeadlineContext);
  const sidebar = useContext(SidebarContext);

  const [cluster, setCluster] = useState(null);

  useBreadcrumbs(useContext(BreadcrumbContext), <BackToClustersLink />)

  useEffect(() => {
    sidebar.set(<ClusterDetailsSidebar clusterId={uuid} />);
    setCluster(null);
    clusterService.findOneCluster(uuid, setCluster);
    return () => {
      sidebar.set(null);
    };
  }, [uuid]);

  useEffect(() => {
    if (cluster !== null) {
      headline.set(<ClusterHeadline cluster={cluster} />)
      sidebar.set(<ClusterDetailsSidebar cluster={cluster} clusterId={uuid} />);
    }

    return () => {
      headline.set(null);
      sidebar.set(null);
    };
  }, [cluster]);

  const dataTypeEnabled = (enabled) => {
    if (enabled) {
      return <i className="bx bx-check-circle color-success" title={"Data type is provided to cluster."} />
    } else {
      return <i className="bx bx-stop-circle color-muted" title={"Data type is not provided to cluster."} />
    }
  }

  if (!cluster) {
    return <LoadingDialog />
  }

  return (
      <React.Fragment>
      <div className="row">
          <div className="col col-12">
            <div className="section">
              <h2>Throughput</h2>

              <ClusterTapThroughputChart cluster={cluster}
                                         xAxis={{lineWidth: 1, lineColor: "#f3f3f3", gridColor: "#f3f3f3"}}
                                         yAxis={{lineWidth: 1, lineColor: "#f3f3f3", gridColor: "#f3f3f3"}}
                                         colors={{bar: "#a7bee3", barEmptyChart: "rgba(47,47,255,0.05)"}}
                                         margins={{l: 75, r: 25, b: 35, t: 0, pad: 0}}
                                         height={200}/>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col col-12">
            <div className="section">
              <h2>Provided Data</h2>

              <PrimaryHelp><em>Connect</em> is providing this cluster with the following data types.</PrimaryHelp>

              <table className="table table-hover">
                <thead>
                <tr>
                  <th style={{width: 25}}>&nbsp;</th>
                  <th>Data Type</th>
                  <th>Remarks</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>{dataTypeEnabled(cluster.data_type_geoip_enabled)}</td>
                  <td>GeoIP</td>
                  <td>IPinfo Free Country IP/ASN</td>
                </tr>
                <tr>
                  <td>{dataTypeEnabled(cluster.data_type_oui_enabled)}</td>
                  <td>OUI</td>
                  <td>IEEE OUI database</td>
                </tr>
                <tr>
                  <td>{dataTypeEnabled(cluster.data_type_bluetooth_enabled)}</td>
                  <td>Bluetooth</td>
                  <td>Company and Service data from Bluetooth SIG</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </React.Fragment>
  )

}