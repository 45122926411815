import React, {useContext, useEffect, useState} from "react";
import {BreadcrumbContext, HeadlineContext, SidebarContext, UserContext} from "../../LoggedInApp";
import {DateTime} from "luxon";
import useBreadcrumbs from "../../ui/useBreadcrumbs";
import BackToClustersLink from "../../ui/BackToClustersLink";
import ButtonSubmittingText from "../../ui/ButtonSubmittingText";
import useUserService from "../../../services/UserService";
import AlertDanger from "../../ui/AlertDanger";
import {useAuth0} from "@auth0/auth0-react";
import PrimaryHelp from "../../ui/PrimaryHelp";
import LoadingDialog from "../../misc/LoadingDialog";

export default function UserSettingsPage() {

  const { logout } = useAuth0();
  const headline = useContext(HeadlineContext);
  const sidebar = useContext(SidebarContext);

  const userService = useUserService();

  const user = useContext(UserContext);

  const [email, setEmail] = useState("");
  const [emailIsSubmitting, setEmailIsSubmitting] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [passwordIsSubmitting, setPasswordIsSubmitting] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordHelp, setPasswordHelp] = useState(null);

  const [deleteConfirmed, setDeleteConfirmed] = useState(false);

  const [revision, setRevision] = useState(null);

  useBreadcrumbs(useContext(BreadcrumbContext), <BackToClustersLink />)

  useEffect(() => {
    headline.set(<h1>Your User Profile</h1>)

    return () => {
      headline.set(null);
      sidebar.set(null);
    };
  }, []);

  useEffect(() => {
    if (user != null) {
      setEmail(user.user.email);
    }
  }, [user]);

  useEffect(() => {
    if (revision !== null) {
      user.setUser(null);
    }
  }, [revision]);

  useEffect(() => {
    const specialChars = /[!@#$%^&*]/;
    const lowerCase = /[a-z]/;
    const upperCase = /[A-Z]/;
    const numbers = /[0-9]/;

    if (!password && !passwordConfirmation) {
      setPasswordHelp(null);
      setPasswordValid(false);
      return;
    }

    if (password !== passwordConfirmation) {
      setPasswordHelp("Passwords do not match.");
      setPasswordValid(false);
      return;
    }

    if (!password || password.trim().length === 0) {
      setPasswordHelp("Password cannot be empty.");
      setPasswordValid(false);
      return;
    }

    if (password.length < 8) {
      setPasswordHelp("Password must be at least 8 characters long.");
      setPasswordValid(false);
      return;
    }

    if (!specialChars.test(password)) {
      setPasswordHelp("Password must contain at least one special character (!@#$%^&*).");
      setPasswordValid(false);
      return;
    }

    if (!lowerCase.test(password)) {
      setPasswordHelp("Password must contain at least one lowercase letter (a-z).");
      setPasswordValid(false);
      return;
    }

    if (!upperCase.test(password)) {
      setPasswordHelp("Password must contain at least one uppercase letter (A-Z).");
      setPasswordValid(false);
      return;
    }

    if (!numbers.test(password)) {
      setPasswordHelp("Password must contain at least one number (0-9).");
      setPasswordValid(false);
      return;
    }

    for (let i = 0; i < password.length - 2; i++) {
      if (password[i] === password[i + 1] && password[i] === password[i + 2]) {
        setPasswordHelp("Password cannot have more than two identical characters in a row.");
        setPasswordValid(false);
        return;
      }
    }

    // Password is valid.
    setPasswordHelp(null);
    setPasswordValid(true);
  }, [password, passwordConfirmation]);

  const submitEmail = (e) => {
    e.preventDefault();
    setEmailIsSubmitting(true);

    userService.changeOwnProfile({email: email}, () => {
      setEmailIsSubmitting(false);
      setRevision(DateTime.now());

      toast.success("Profile updated.");
    })
  }

  const submitPassword = (e) => {
    e.preventDefault();
    setPasswordIsSubmitting(true);

    userService.changeOwnPassword(password, () => {
      setPasswordIsSubmitting(false);
      setRevision(DateTime.now());

      toast.success("Password updated.");
    })
  }

  const onClickDeleteAccount = () => {
    if (!confirm("Really delete your account? This cannot be undone.")) {
      return;
    }

    userService.deleteOwnUser(() => {
      logout({logoutParams: {returnTo: process.env.AUTH0_LOGOUT_CALLBACK_URI}})
    })
  }


  const passwordHelpMessage = () => {
    console.log(passwordHelp);
    if (!passwordHelp) {
      return null;
    }

    return <AlertDanger>{passwordHelp}</AlertDanger>
  }

  const emailFormIsReady = () => {
    return email && email.trim().length > 0
  }

  if (!user.user) {
    return <LoadingDialog />
  }

  return (
      <React.Fragment>
        <div className="row">
          <div className="col col-12">
            <div className="section">
              <h2>Change Email Address</h2>

              <form onSubmit={submitEmail}>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Email Address</label>
                  <input type="email"
                         className="form-control"
                         id="email"
                         value={email}
                         onChange={(e) => setEmail(e.target.value)}/>
                  <div className="form-text">
                    Your new email address.
                  </div>
                </div>

                <button type="submit" className="btn btn-primary" disabled={emailIsSubmitting || !emailFormIsReady()}>
                  {emailIsSubmitting ? <ButtonSubmittingText/> : "Change Email Address"}
                </button>
              </form>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col col-12">
            <div className="section">
              <h2>Change Password</h2>

              <h3>Password Requirements:</h3>

              <ul>
                <li>Must <strong>not</strong> include more than 2 identical characters in a row</li>
                <li><strong>Must</strong> include special characters (<code>!@#$%^&*</code>)</li>
                <li><strong>Must</strong> include lower case (<code>a-z</code>), upper case (<code>A-Z</code>)
                  and numbers (<code>0-9</code>)
                </li>
                <li><strong>Must</strong> have 8 characters in length</li>
              </ul>

              <form onSubmit={submitPassword}>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">New Password</label>
                  <input type="password"
                         className="form-control"
                         id="password"
                         value={password}
                         onChange={(e) => setPassword(e.target.value)}/>
                  <div className="form-text">
                    Your new password.
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="passwordConfirm" className="form-label">Confirm New Password</label>
                  <input type="password"
                         className="form-control"
                         id="passwordConfirm"
                         value={passwordConfirmation}
                         onChange={(e) => setPasswordConfirmation(e.target.value)}/>
                  <div className="form-text">
                    Enter your new password again.
                  </div>
                </div>

                {passwordHelpMessage()}

                <button type="submit" className="btn btn-primary"
                        disabled={passwordIsSubmitting || !passwordValid}>
                  {passwordIsSubmitting ? <ButtonSubmittingText/> : "Change Password"}
                </button>
              </form>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col col-12">
            <div className="section">
              <h2>Delete Account</h2>

              <PrimaryHelp>You can delete your account at any time. This cannot be undone.</PrimaryHelp>

              <div className="row">
                <div className="col-8">
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="confirmed"
                           checked={deleteConfirmed} onChange={() => setDeleteConfirmed(!deleteConfirmed)}/>

                    <label className="form-check-label" htmlFor="confirmed">
                      I understand that I cannot undo deleting my <em>Connect</em> account and <strong>all related data
                      will be lost</strong>.
                    </label>
                  </div>
                </div>
              </div>

              <button className="btn btn-danger mt-3" onClick={onClickDeleteAccount} disabled={!deleteConfirmed}>
                Delete Your Account
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
  )

}