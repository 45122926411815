import React from "react";

export default function AlertInfo(props) {

  return (
      <div className="alert alert-info mb-0">
        <i className="bx bxs-square alert-icon"></i> {props.children}
      </div>
  )

}