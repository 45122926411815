import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import useClusterService from "../../../../services/ClusterService";
import {BreadcrumbContext, HeadlineContext, SidebarContext} from "../../../LoggedInApp";
import useBreadcrumbs from "../../../ui/useBreadcrumbs";
import BackToClustersLink from "../../../ui/BackToClustersLink";
import ClusterDetailsSidebar from "../ClusterDetailsSidebar";
import ClusterHeadline from "../../../ui/ClusterHeadline";
import AlertInfo from "../../../ui/AlertInfo";
import LoadingDialog from "../../../misc/LoadingDialog";

export default function SupportPage() {

  const { uuid } = useParams();

  const clusterService = useClusterService();
  const headline = useContext(HeadlineContext);
  const sidebar = useContext(SidebarContext);

  const [cluster, setCluster] = useState(null);

  useBreadcrumbs(useContext(BreadcrumbContext), <BackToClustersLink />)

  useEffect(() => {
    sidebar.set(<ClusterDetailsSidebar cluster={null} clusterId={uuid} />);
    setCluster(null);
    clusterService.findOneCluster(uuid, setCluster);
    return () => {
      sidebar.set(null);
    };
  }, [uuid]);

  useEffect(() => {
    if (cluster !== null) {
      headline.set(<ClusterHeadline cluster={cluster} />)
      sidebar.set(<ClusterDetailsSidebar cluster={cluster} clusterId={uuid} />);
    }

    return () => {
      headline.set(null);
      sidebar.set(null);
    };
  }, [cluster]);

  if (!cluster) {
    return <LoadingDialog />
  }

  return (
      <React.Fragment>
        <div className="row">
          <div className="col col-12">
            <div className="section">
              <h2>Customer Support</h2>

              <AlertInfo>
                To ensure the highest level of service, support is currently available only to select early customers.
                Please <a href="https://go.nzyme.org/contact" target="_blank">contact us</a> if you are interested.
              </AlertInfo>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col col-12">
            <div className="section">
              <h2>Community Support</h2>

              <p>
                Our community support channels are available to everyone and are actively staffed:
              </p>

              <ul>
                <li>
                  Bug Reports and Feature Requests:{' '}
                  <a href="https://github.com/lennartkoopmann/nzyme/issues" target="_blank">GitHub issues</a>
                </li>
                <li>
                  Questions and Feedback:{' '}
                  <a href="https://discord.gg/9W2tVtXUny" target="_blank">Discord</a> or{' '}
                  <a href="https://github.com/lennartkoopmann/nzyme/discussions" target="_blank">nzyme Discussion Forums</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </React.Fragment>
  )

}