import React from "react";
import {DateTime} from "luxon";

export default function ReportTime(props) {

 const time = props.time;

 if (!time) {
   return "Never"
 }

 return <span>{DateTime.fromISO(time).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}</span>

}