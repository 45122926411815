import React, {useContext, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import useClusterService from "../../../../../services/ClusterService";
import {BreadcrumbContext, HeadlineContext, SidebarContext} from "../../../../LoggedInApp";
import useBreadcrumbs from "../../../../ui/useBreadcrumbs";
import BackToClustersLink from "../../../../ui/BackToClustersLink";
import ClusterDetailsSidebar from "../../ClusterDetailsSidebar";
import ClusterHeadline from "../../../../ui/ClusterHeadline";
import PrimaryHelp from "../../../../ui/PrimaryHelp";
import useClusterDataService from "../../../../../services/ClusterDataService";

import numeral from "numeral";
import DataStatus from "../DataStatus";
import {DateTime} from "luxon";
import LoadingDialog from "../../../../misc/LoadingDialog";

export default function BluetoothPage() {

  const { uuid } = useParams();

  const clusterService = useClusterService();
  const clusterDataService = useClusterDataService();

  const headline = useContext(HeadlineContext);
  const sidebar = useContext(SidebarContext);

  const [cluster, setCluster] = useState(null);
  const [summary, setSummary] = useState(null);

  const [revision, setRevision] = useState(DateTime.now());

  useBreadcrumbs(useContext(BreadcrumbContext), <BackToClustersLink />)

  useEffect(() => {
    sidebar.set(<ClusterDetailsSidebar cluster={null} clusterId={uuid} />);
    setCluster(null);
    clusterService.findOneCluster(uuid, setCluster);
    return () => {
      sidebar.set(null);
    };
  }, [uuid]);

  useEffect(() => {
    if (cluster !== null) {
      headline.set(<ClusterHeadline cluster={cluster} />)
      sidebar.set(<ClusterDetailsSidebar cluster={cluster} clusterId={uuid} />);

      setSummary(null);
      clusterDataService.getBluetoothSummary(cluster.uuid, setSummary);
    }

    return () => {
      headline.set(null);
      sidebar.set(null);
      setSummary(null);
    };
  }, [cluster, revision]);

  const onChange = () => {
    setRevision(DateTime.now());
  }

  if (!cluster || !summary) {
    return <LoadingDialog />
  }

  return (
      <React.Fragment>
        <div className="row">
          <div className="col col-12">
            <div className="section">
              <h2>Bluetooth</h2>

              <PrimaryHelp>
                The <em>Connect</em> service can supply vendor and device information to the nzyme Bluetooth subsystem.
                This information is sourced from the <em>Bluetooth Special Interest Group</em> (SIG), the standards
                organization responsible for overseeing the development of Bluetooth standards.
              </PrimaryHelp>

              <PrimaryHelp>
                This data is exclusively used in the nzyme Bluetooth subsystem and can be disabled if the subsystem
                is not in use.
              </PrimaryHelp>

              <DataStatus dataType="bluetooth" cluster={cluster} status={summary.is_enabled} onChange={onChange} />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col col-12">
            <div className="section">
              <h3>Provided Data</h3>

              <table className="table table-hover">
                <thead>
                <tr>
                  <th>Data Type</th>
                  <th>Record Count</th>
                  <th>Description</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>Bluetooth Company IDs</td>
                  <td>{numeral(summary.company_ids.size).format("0,0")}</td>
                  <td>Used to indicate the manufacturer of discovered Bluetooth devices.</td>
                </tr>
                <tr>
                  <td>Bluetooth Service UUIDs</td>
                  <td>{numeral(summary.service_uuids.size).format("0,0")}</td>
                  <td>Used to discover services supported by Bluetooth devices.</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </React.Fragment>
  )

}