import React, {useContext, useEffect, useState} from "react";
import ClustersTable from "./ClustersTable";
import useClusterService from "../../../services/ClusterService";
import useBreadcrumbs from "../../ui/useBreadcrumbs";
import {ActionButtonsContext, BreadcrumbContext} from "../../LoggedInApp";
import useActionButtons from "../../ui/useActionButtons";
import {Link} from "react-router-dom";
import {LoggedInRoutes} from "../../../LoggedInRoutes";
import {DateTime} from "luxon";
import LoadingDialog from "../../misc/LoadingDialog";

export default function ClustersIndexPage() {

  const clusterService = useClusterService();

  useBreadcrumbs(useContext(BreadcrumbContext),
      <span className="color-muted d-none d-md-inline">{DateTime.now().toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}</span>
  )

  useActionButtons(useContext(ActionButtonsContext),
      <Link to={LoggedInRoutes.CLUSTERS.MGMT.CREATE}
            className="btn btn-primary"
            style={{marginLeft: "auto"}}>
        <i className="bx bx-plus"></i> Add <span className="d-none d-md-inline">Cluster</span>
      </Link>
)

const [clusters, setClusters] = useState(null);

  useEffect(() => {
    clusterService.findAllClusters(setClusters);
  }, []);

  if (!clusters) {
    return <LoadingDialog />
  }

  return (
      <React.Fragment>
        <div className="row pt-3">
          <div className="col-12">
            <ClustersTable clusters={clusters.clusters}/>
          </div>
        </div>
      </React.Fragment>
)

}