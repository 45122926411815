import React, {useContext, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import useClusterService from "../../../../../services/ClusterService";
import {BreadcrumbContext, HeadlineContext, SidebarContext} from "../../../../LoggedInApp";
import useBreadcrumbs from "../../../../ui/useBreadcrumbs";
import BackToClustersLink from "../../../../ui/BackToClustersLink";
import ClusterDetailsSidebar from "../../ClusterDetailsSidebar";
import ClusterHeadline from "../../../../ui/ClusterHeadline";
import useClusterDataService from "../../../../../services/ClusterDataService";
import {DateTime} from "luxon";
import PrimaryHelp from "../../../../ui/PrimaryHelp";
import DataStatus from "../DataStatus";
import numeral from "numeral";
import LoadingDialog from "../../../../misc/LoadingDialog";

export default function OuisPage() {

  const { uuid } = useParams();

  const clusterService = useClusterService();
  const clusterDataService = useClusterDataService();

  const headline = useContext(HeadlineContext);
  const sidebar = useContext(SidebarContext);

  const [cluster, setCluster] = useState(null);
  const [summary, setSummary] = useState(null);

  const [revision, setRevision] = useState(DateTime.now());

  useBreadcrumbs(useContext(BreadcrumbContext), <BackToClustersLink />)

  useEffect(() => {
    sidebar.set(<ClusterDetailsSidebar cluster={null} clusterId={uuid} />);
    setCluster(null);
    clusterService.findOneCluster(uuid, setCluster);
    return () => {
      sidebar.set(null);
    };
  }, [uuid]);

  useEffect(() => {
    if (cluster !== null) {
      headline.set(<ClusterHeadline cluster={cluster} />)
      sidebar.set(<ClusterDetailsSidebar cluster={cluster} clusterId={uuid} />);

      setSummary(null);
      clusterDataService.getOuiSummary(cluster.uuid, setSummary);
    }

    return () => {
      headline.set(null);
      sidebar.set(null);
      setSummary(null);
    };
  }, [cluster, revision]);

  const onChange = () => {
    setRevision(DateTime.now());
  }

  if (!cluster || !summary) {
    return <LoadingDialog />
  }


  return (
      <React.Fragment>
        <div className="row">
          <div className="col col-12">
            <div className="section">
              <h2>OUI / MAC Address Vendor Information</h2>

              <PrimaryHelp>
                The <em>Connect</em> service provides MAC address vendor and device information to various subsystems.
                This data is used to identify the vendor associated with a MAC address. For example, a MAC address
                starting with <code>E8:0A:B9</code> (the OUI, or <em>Organizationally Unique Identifier</em>) indicates
                the device using it it is manufactured by Cisco.
              </PrimaryHelp>

              <DataStatus dataType="oui" cluster={cluster} status={summary.is_enabled} onChange={onChange} />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col col-12">
            <div className="section">
              <h3>Provided Data</h3>

              <table className="table table-hover">
                <thead>
                <tr>
                  <th>Data Type</th>
                  <th>Record Count</th>
                  <th>Description</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>IEEE OUI Database</td>
                  <td>{numeral(summary.ieee_ouis.size).format("0,0")}</td>
                  <td>OUIs provided by the official IEEE OUI database</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </React.Fragment>
  )

}