import React from "react";

export default function AlertSuccess(props) {

  const hideIcon = props.hideIcon;

  return (
      <div className="alert alert-success mb-0">
        { hideIcon ? null : <i className="bx bx-check-circle alert-icon"></i>} {props.children}
      </div>
  )

}