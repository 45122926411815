import React, {useEffect, useState} from "react";
import AlertInfo from "../../../ui/AlertInfo";
import useClusterService from "../../../../services/ClusterService";
import ReportTime from "../ui/ReportTime";
import SystemEntityStatus from "../ui/SystemEntityStatus";
import numeral from "numeral";
import CriticalLogCount from "../ui/CriticalLogCount";
import LoadingDialog from "../../../misc/LoadingDialog";

export default function TapsTable(props) {

  const clusterService = useClusterService();

  const cluster = props.cluster;

  const [taps, setTaps] = useState(null);

  useEffect(() => {
    clusterService.findTapsOfCluster(cluster.uuid, setTaps);
  }, [cluster]);

  if (taps == null) {
    return <LoadingDialog />
  }

  if (taps.taps.length === 0) {
    return <AlertInfo>No taps found.</AlertInfo>
  }

  return (
      <React.Fragment>
        <p>
          {taps.taps.length} {taps.taps.length > 1 ? "taps" : "tap"} reporting to this cluster.
        </p>

        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
            <tr>
              <th>Name</th>
              <th>CPU</th>
              <th>Memory</th>
              <th>Critical Logs</th>
              <th>Last Report</th>
            </tr>
            </thead>
            <tbody>
            {taps.taps.map((t, i) => {
              if (t.status === "ONLINE") {
                return (
                    <tr key={i}>
                      <td>
                        <SystemEntityStatus status={t.status}/>{' '}
                        {t.name}
                      </td>
                      <td>{numeral(t.cpu_utilization).format("0,0.0")}%</td>
                      <td>{numeral(t.memory_utilization).format("0,0.0")}%</td>
                      <td><CriticalLogCount count={t.critical_logs} /></td>
                      <td><ReportTime time={t.last_report_received_at}/></td>
                    </tr>
                )
              } else {
                return (
                    <tr key={i}>
                      <td>
                        <SystemEntityStatus status={t.status}/>{' '}
                        {t.name}
                      </td>
                      <td className="color-muted">n/a</td>
                      <td className="color-muted">n/a</td>
                      <td className="color-muted">n/a</td>
                      <td><ReportTime time={t.last_report_received_at}/></td>
                    </tr>
                )
              }
            })}
            </tbody>
          </table>
        </div>
      </React.Fragment>
  )

}