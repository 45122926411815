import React from "react";
import LoginButton from "../auth/LoginButton";

export default function HomePage() {

  return (
      <React.Fragment>
        <div className="row mt-5 pt-5" style={{maxWidth: 600, margin: "0 auto"}}>
          <div className="col-12 text-center">
            <h1>Sign in to use nzyme Connect.</h1>

            <p className="lead mt-3">
              The Connect APIs provide up-to-date, curated operational data for your nzyme setups, including GeoIP, ASN
              and MAC address vendor information.
            </p>

            <p className="mt-5 mb-5">
              <LoginButton />
            </p>
          </div>
        </div>
      </React.Fragment>
)

}