import React from "react";
import SystemEntityStatus from "./ui/SystemEntityStatus";
import {Link} from "react-router-dom";
import {LoggedInRoutes} from "../../../LoggedInRoutes";
import ClusterHealthStatus from "./ui/ClusterHealthStatus";
import ClusterTapThroughputChart from "./ClusterTapThroughputChart";
import CurrentClusterThroughput from "./CurrentClusterThroughput";

export default function ClusterRow(props) {

  const cluster = props.cluster;

  return (
      <tr>
        <td style={{whiteSpace: "nowrap", verticalAlign: "middle"}}>
          <SystemEntityStatus status={cluster.status}/>{' '}
          <Link to={LoggedInRoutes.CLUSTERS.SHOW(cluster.uuid)} className="cluster-name">
            {cluster.name}
          </Link>
        </td>
        <td style={{paddingLeft: 20, paddingRight: 10}}>
          <ClusterTapThroughputChart cluster={cluster}
                                     xAxis={{lineWidth: 0, lineColor: "#fff", gridColor: "#c9c9c9"}}
                                     yAxis={{lineWidth: 0, lineColor: "#fff", gridColor: "#fff"}}
                                     colors={{bar: "#a7bee3", barEmptyChart: "rgba(47,47,255,0.05)"}}
                                     margins={{l: 0, r: 0, b: 0, t: 0, pad: 0}}
                                     height={35} />
        </td>
        <td style={{verticalAlign: "middle", textAlign: "center", whiteSpace: "nowrap"}}>
          <CurrentClusterThroughput cluster={cluster} />
        </td>
        <td style={{verticalAlign: "middle", textAlign: "center"}}>
          <ClusterHealthStatus cluster={cluster} showGreen={true} />
        </td>
        <td style={{verticalAlign: "middle"}}>
          <Link to={LoggedInRoutes.CLUSTERS.SHOW(cluster.uuid)}>
            <i className="bx bx-chevrons-right cluster-advance" title="Show Cluster Details" />
          </Link>
        </td>
      </tr>
  )

}