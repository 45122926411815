import React, {useContext} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {UserContext} from "../LoggedInApp";
import {Link} from "react-router-dom";
import {LoggedInRoutes} from "../../LoggedInRoutes";
import LoadingSpinner from "../misc/LoadingSpinner";

export default function UserMenu() {

  const { logout } = useAuth0();

  const user = useContext(UserContext);

  const signOut = (e) => {
    e.preventDefault();

    if (!confirm("Really sign out?")) {
      return;
    }

    logout({logoutParams: {returnTo: process.env.AUTH0_LOGOUT_CALLBACK_URI}})
  }

  const email = (str) => {
    const maxLength = 50;
    const ellipsis = '…';

    if (str.length <= maxLength) {
      return str;
    }

    const charsToShow = maxLength - ellipsis.length;
    const frontChars = Math.ceil(charsToShow / 2);
    const backChars = Math.floor(charsToShow / 2);

    return str.slice(0, frontChars) + ellipsis + str.slice(str.length - backChars);
  }

  if (!user.user) {
    return (
        <div className="dropdown">
          <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown">
            <i className="bx bx-user"></i> <LoadingSpinner />
          </button>
        </div>
    )
  }

  return (
      <React.Fragment>
        <div className="dropdown">
          <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown">
            <i className="bx bx-user"></i> <span className="d-none d-md-inline">{email(user.user.email)}</span>
          </button>
          <ul className="dropdown-menu">
            <li><Link to={LoggedInRoutes.USER.SETTINGS} className="dropdown-item">Settings</Link></li>
            <li>
              <hr className="dropdown-divider"/>
            </li>
            <li><a className="dropdown-item" href="#" onClick={signOut}>Sign Out</a></li>
          </ul>
        </div>
      </React.Fragment>
  )

}