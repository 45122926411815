import React from "react";

export default function LoadingDialog() {

  return (
      <div className="loading-dialog">
        <div className="row">
          <div className="col col-12">
            <img src="/static/img/loading-miller.png"
                 className="loading-miller"
                 alt="Connect is initializing"/>
            <img src="/static/img/loading-miller_layer2.png"
                 className="loading-miller loading-miller-layer2"
                 alt="nzyme is initializing"/>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col col-12">
            <span className="loading-dialog-text">Please wait ... Processing Data</span>
          </div>
        </div>
      </div>
  )

}