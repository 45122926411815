import React from "react";
import AlertInfo from "../../ui/AlertInfo";

export default function NeverReportedPage() {

  return (
      <div className="row">
        <div className="col col-12">
          <div className="section">
            <AlertInfo>This cluster has never reported data.</AlertInfo>
          </div>
        </div>
      </div>
  )

}