import React from "react";

import numeral from "numeral";

export default function Throughput(props) {

  // Bits/sec
  const tpx = props.throughput;

  let formattedValue;
  let unit;

  if (tpx >= 1e9) {
    formattedValue = (tpx / 1024 / 1024 / 1024);
    unit = 'Gbit/s';
  } else {
    formattedValue = (tpx / 1024 / 1024);
    unit = 'Mbit/s';
  }

  return (
      <span>{numeral(formattedValue).format("0.00")}<br />{unit}</span>
  );

}