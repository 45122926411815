import React from "react";

export default function AlertDanger(props) {

  return (
      <div className="alert alert-danger">
        <i className="bx bx-error alert-icon"></i> {props.children}
      </div>
  )

}