import React from "react";
import {Auth0Provider} from "@auth0/auth0-react";
import AuthenticationRouter from "./AuthenticationRouter";

import { PostHogProvider} from 'posthog-js/react'
import * as Sentry from "@sentry/react";

const posthogOptions = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: []
});

export default function App() {

  return (
      <Auth0Provider
          useRefreshTokens
          cacheLocation="localstorage"
          domain={process.env.AUTH0_DOMAIN}
          clientId={process.env.AUTH0_CLIENTID}
          authorizationParams={{
            redirect_uri: process.env.AUTH0_LOGIN_CALLBACK_URI,
            audience: process.env.AUTH0_CONNECT_API_AUDIENCE,
            scope: "offline_access read:api_keys"}}>

        <PostHogProvider
            apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
            options={posthogOptions} >
          <AuthenticationRouter />
        </PostHogProvider>
      </Auth0Provider>
  )

}