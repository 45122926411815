import React from "react";

export default function SystemEntityStatus(props) {

  const status = props.status;

  switch (status) {
    case "ONLINE":
      return <i className="bx bxs-square status-icon-success system-status-icon" title="System is reporting"></i>
    case "OFFLINE":
      return <i className="bx bxs-square status-icon-danger system-status-icon" title="System is not reporting"></i>
    case "INACTIVE":
      return <i className="bx bxs-square status-icon-inactive system-status-icon" title="System has never reported"></i>
  }

}