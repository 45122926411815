import React from "react";
import LoggedInApp from "./components/LoggedInApp";
import LoggedOutApp from "./components/LoggedOutApp";
import {useAuth0} from "@auth0/auth0-react";
import LoadingSpinner from "./components/misc/LoadingSpinner";
import AuthenticationError from "./components/misc/AuthenticationError";

export default function AuthenticationRouter() {

  const { isAuthenticated, isLoading, error } = useAuth0();

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <AuthenticationError />
  }

  return isAuthenticated ? <LoggedInApp /> : <LoggedOutApp />

}