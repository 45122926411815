import React from "react";
import {Link} from "react-router-dom";
import {LoggedInRoutes} from "../../LoggedInRoutes";

export default function BackToClustersLink() {

  return (
      <Link to={LoggedInRoutes.CLUSTERS.INDEX} title="Back to all clusters">
        <i className="bx bx-chevrons-left cluster-back"/>
      </Link>
  )

}