import React from "react";
import SystemEntityStatus from "../loggedin/clusters/ui/SystemEntityStatus";
import ClusterHealthStatus from "../loggedin/clusters/ui/ClusterHealthStatus";

export default function ClusterHeadline(props) {

  const cluster = props.cluster;

  if (cluster == null) {
    return null;
  }

  return (
      <h1 className="cluster-details-title">
        <ClusterHealthStatus cluster={cluster} /> <SystemEntityStatus status={cluster.status}/> {cluster.name}
      </h1>
  )

}