import React, {useEffect} from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink, Link
} from 'react-router-dom'
import HomePage from "./loggedout/HomePage";
import {LoggedOutRoutes} from "../LoggedOutRoutes";
import FeaturesPage from "./loggedout/FeaturesPage";
import HowtoPage from "./loggedout/HowtoPage";
import Store from "../lib/Store";
import PrivacyPolicyPage from "./loggedout/PrivacyPolicyPage";
import CookiePolicyPage from "./loggedout/CookiePolicyPage";
import TermsConditionsPage from "./loggedout/TermsConditionsPage";
import AcceptableUsePage from "./loggedout/AcceptableUsePage";
import FaqPage from "./loggedout/FaqPage";
import NotFoundLoggedOutPage from "./misc/NotFoundLoggedOutPage";
import ErrorLoggedOutPage from "./misc/ErrorLoggedOutPage";

export default function LoggedOutApp() {

  useEffect(() => {
    Store.delete("api_token");
  }, []);

  return (
      <Router>
        <div className="container container-loggedout py-3">
          <header>
            <div className="d-flex flex-column flex-md-row align-items-center pb-3 mb-4">
              <Link className="fs-4 brand" to={LoggedOutRoutes.HOME}>
                <img src="/static/img/logo-black.png" alt="Connect"/>
              </Link>

              <nav className="nav-header d-inline-flex mt-2 mt-md-0 ms-md-auto">
                <NavLink className="nav-link me-3 py-2 text-decoration-none" to={LoggedOutRoutes.HOME}>Home</NavLink>
                <NavLink className="nav-link me-3 py-2 text-decoration-none" to={LoggedOutRoutes.FEATURES}>Features</NavLink>
                <NavLink className="nav-link me-3 py-2 text-decoration-none" to={LoggedOutRoutes.FAQ}>FAQ</NavLink>
                <NavLink className="nav-link me-3 py-2 text-decoration-none" to={LoggedOutRoutes.HOWTO}>How To</NavLink>
              </nav>
            </div>
          </header>

          <main className="mb-5">
            <Routes>
              <Route path={LoggedOutRoutes.HOME} element={<HomePage/>}/>
              <Route path={LoggedOutRoutes.FEATURES} element={<FeaturesPage/>}/>
              <Route path={LoggedOutRoutes.FAQ} element={<FaqPage/>}/>
              <Route path={LoggedOutRoutes.HOWTO} element={<HowtoPage/>}/>

              <Route path={LoggedOutRoutes.PRIVACY_POLICY} element={<PrivacyPolicyPage/>}/>
              <Route path={LoggedOutRoutes.COOKIE_POLICY} element={<CookiePolicyPage/>}/>
              <Route path={LoggedOutRoutes.TERMS_CONDITIONS} element={<TermsConditionsPage/>}/>
              <Route path={LoggedOutRoutes.ACCEPTABLE_USE} element={<AcceptableUsePage/>}/>

              { /* Error. (Used by Auth0) */}
              <Route path={LoggedOutRoutes.ERROR} element={<ErrorLoggedOutPage/>}/>

              { /* Catch-all / 404. */}
              <Route path="*" element={<NotFoundLoggedOutPage />}/>
            </Routes>
          </main>

          <footer className="mt-5 pt-3">
            <div className="row">
              <div className="col-12">
                <div className="footer-container">
                  <ul className="text-small">
                    <li>
                      <Link className="link-secondary text-decoration-none" to={LoggedOutRoutes.HOME}>
                        Home
                      </Link>
                    </li>
                    <li>
                      <a className="link-secondary text-decoration-none"
                         href="https://status.connect.nzyme.org/"
                         target="_blank">
                        Status
                      </a>
                    </li>
                    <li>
                      <a className="link-secondary text-decoration-none" href="https://www.nzyme.org/">
                        nzyme.org
                      </a>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link className="link-secondary text-decoration-none" to={LoggedOutRoutes.TERMS_CONDITIONS}>
                        Terms &amp; Conditions
                      </Link>
                    </li>
                    <li>
                      <Link className="link-secondary text-decoration-none" to={LoggedOutRoutes.ACCEPTABLE_USE}>
                        Acceptable Use Policy
                      </Link>
                    </li>
                    <li>
                      <Link className="link-secondary text-decoration-none" to={LoggedOutRoutes.PRIVACY_POLICY}>
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link className="link-secondary text-decoration-none" to={LoggedOutRoutes.COOKIE_POLICY}>
                        Cookie Policy
                      </Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <a href="#"
                         onClick={() => {
                           window.displayPreferenceModal();
                           return false;
                         }}
                         id="termly-consent-preferences"
                         className="link-secondary text-decoration-none">Consent Preferences</a>
                    </li>
                    <li>
                      <a className="link-secondary text-decoration-none"
                         href="https://app.termly.io/notify/17afab85-64a4-411e-8248-3acc224f0214"
                         target="_blank">
                        Data Subject Access Request
                      </a>
                    </li>
                    <li>
                      <a href="https://app.termly.io/notify/17afab85-64a4-411e-8248-3acc224f0214"
                         className="link-secondary text-decoration-none" target="_blank">
                        Do Not Sell or Share My Personal information
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </Router>
  )

}