import {useEffect, useState} from 'react';

const useHeadline = (headline, text) => {
  useEffect(() => {
    headline.set(text);

    return () => headline.set(null);
  }, [text]);
};

export default useHeadline;