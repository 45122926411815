import React, {createContext, useEffect, useState} from "react";

import {useAuth0} from "@auth0/auth0-react";
import Store from "../lib/Store";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from 'react-router-dom'
import {LoggedInRoutes} from "../LoggedInRoutes";
import ClustersIndexPage from "./loggedin/clusters/ClustersIndexPage";
import UserSettingsPage from "./loggedin/profile/UserSettingsPage";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import CreateClusterPage from "./loggedin/clusters/mgmt/CreateClusterPage";
import ClusterOverviewPage from "./loggedin/clusters/overview/ClusterOverviewPage";
import NodesPage from "./loggedin/clusters/nodes/NodesPage";
import NotFoundPage from "./misc/NotFoundPage";
import ClusterHealthPage from "./loggedin/clusters/health/ClusterHealthPage";
import OuisPage from "./loggedin/clusters/data/ouis/OuisPage";
import BluetoothPage from "./loggedin/clusters/data/bluetooth/BluetoothPage";
import GeoIpPage from "./loggedin/clusters/data/geoip/GeoIpPage";
import ClusterApiKeyPage from "./loggedin/clusters/apikey/ClusterApiKeyPage";
import ClusterSettingsPage from "./loggedin/clusters/settings/ClusterSettingsPage";
import SupportPage from "./loggedin/clusters/support/SupportPage";
import TapsPage from "./loggedin/clusters/taps/TapsPage";
import UserMenu from "./ui/UserMenu";
import useUserService from "../services/UserService";
import LoadingDialog from "./misc/LoadingDialog";

export const BreadcrumbContext = createContext(null);
export const ActionButtonsContext = createContext(null);
export const SidebarContext = createContext(null);
export const HeadlineContext = createContext(null);
export const UserContext = createContext(null);

export default function LoggedInApp() {

  const { getAccessTokenSilently } = useAuth0();
  const userService = useUserService();

  const [apiConnectionReady, setApiConnectionReady] = useState(false);
  const [user, setUser] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState(null);
  const [actionButtons, setActionButtons] = useState(null);
  const [sidebar, setSidebar] = useState(null);
  const [headline, setHeadline] = useState(null)

  const loadAccessToken = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      Store.set("api_token", accessToken);
      setApiConnectionReady(true)
    } catch (e) {
      console.log("Could not fetch API Token.");
    }
  };

  useEffect(() => {
    import("../loggedin.css");
    import("../loggedin-colors.css");

    const apiToken = Store.get("api_token");

    if (apiToken) {
      setApiConnectionReady(true);
    } else {
      loadAccessToken();
    }
  }, []);

  useEffect(() => {
    if (apiConnectionReady && user == null) {
      userService.findOwnUser(setUser);
    }
  }, [apiConnectionReady, user]);

  const mainContent = () => {
    if (!apiConnectionReady || !user) {
      return <LoadingDialog />
    }

    return (
        <React.Fragment>
          <div className="d-sm-block d-md-none sidebar-sm mt-3">
            {sidebar}
          </div>

          <Routes>
            { /* Clusters / Details. */}
            <Route path={LoggedInRoutes.CLUSTERS.INDEX} element={<ClustersIndexPage />}/>
            <Route path={LoggedInRoutes.CLUSTERS.SHOW(":uuid")} element={<ClusterOverviewPage />}/>
            <Route path={LoggedInRoutes.CLUSTERS.NODES(":uuid")} element={<NodesPage />}/>
            <Route path={LoggedInRoutes.CLUSTERS.TAPS(":uuid")} element={<TapsPage />}/>
            <Route path={LoggedInRoutes.CLUSTERS.HEALTH(":uuid")} element={<ClusterHealthPage />}/>
            <Route path={LoggedInRoutes.CLUSTERS.SUPPORT(":uuid")} element={<SupportPage />}/>
            <Route path={LoggedInRoutes.CLUSTERS.APIKEY(":uuid")} element={<ClusterApiKeyPage />}/>

            { /* Clusters / Data. */}
            <Route path={LoggedInRoutes.CLUSTERS.DATA.OUIS(":uuid")} element={<OuisPage />}/>
            <Route path={LoggedInRoutes.CLUSTERS.DATA.BLUETOOTH(":uuid")} element={<BluetoothPage />}/>
            <Route path={LoggedInRoutes.CLUSTERS.DATA.GEOIP(":uuid")} element={<GeoIpPage />}/>

            { /* Clusters / Settings. */}
            <Route path={LoggedInRoutes.CLUSTERS.SETTINGS.INDEX(":uuid")} element={<ClusterSettingsPage />}/>

            <Route path={LoggedInRoutes.CLUSTERS.MGMT.CREATE} element={<CreateClusterPage />}/>

            { /* User Settings / Own User. */}
            <Route path={LoggedInRoutes.USER.SETTINGS} element={<UserSettingsPage />}/>

            { /* Catch-all / 404. */}
            <Route path="*" element={<NotFoundPage />}/>
          </Routes>
        </React.Fragment>
    )
  }

  return (
      <Router>
        <UserContext.Provider value={{user: user, setUser: setUser}}>
          <BreadcrumbContext.Provider value={{set: setBreadcrumbs}}>
            <ActionButtonsContext.Provider value={{set: setActionButtons}}>
              <SidebarContext.Provider value={{set: setSidebar}}>
                <HeadlineContext.Provider value={{set: setHeadline}}>
                  <ToastContainer position="top-center" theme="light" />

                  <div className="container-fluid top-bg">
                    <div className="container-xl">
                      <div className="p-0 top-container">
                        <div className="top-container-left">
                          <Link className="top-brand" to={LoggedInRoutes.CLUSTERS.INDEX}>
                            <img src="/static/img/logo-white.png" alt="Connect" />
                          </Link>
                        </div>
                        <div className="top-container-right">
                          <UserMenu />
                        </div>
                      </div>

                      <div className="container-fluid subtop">
                        <div className="row">
                          <div className="col-12">
                            <div className="subtop-content">
                              <div className="subtop-left subtop-section">
                                {breadcrumbs}
                              </div>

                              <div className="subtop-center subtop-section">
                                {headline}
                              </div>

                              <div className="subtop-right subtop-section">
                                {actionButtons}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="container-fluid">
                    <div className="container-xl">
                      <div className="container-fluid">
                        <div className="row h-md-100">
                          <main className="col-12">
                            <div className="row" style={{margin: 0}}>
                              {sidebar ? <div className="col-2 d-none d-md-block sidebar">{sidebar}</div> : null}

                              <div className={sidebar ? "col-md-10 col-sm-12 main-content main-content-with-sidebar" : "col-12 main-content"}>
                                {mainContent()}
                              </div>
                            </div>
                          </main>

                          <footer className="mt-2 pt-3 mb-3">
                            <div className="row">
                              <div className="col-12">
                                <a href="https://status.connect.nzyme.org/" target="_blank">
                                  Status
                                </a> | pugnantis latus defensantes
                              </div>
                            </div>
                          </footer>
                        </div>
                      </div>
                    </div>
                  </div>
                </HeadlineContext.Provider>
              </SidebarContext.Provider>
            </ActionButtonsContext.Provider>
          </BreadcrumbContext.Provider>
        </UserContext.Provider>
      </Router>
  )

}