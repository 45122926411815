import React, {useContext, useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import useClusterService from "../../../../services/ClusterService";
import {BreadcrumbContext, HeadlineContext, SidebarContext} from "../../../LoggedInApp";
import useBreadcrumbs from "../../../ui/useBreadcrumbs";
import BackToClustersLink from "../../../ui/BackToClustersLink";
import ClusterDetailsSidebar from "../ClusterDetailsSidebar";
import ClusterHeadline from "../../../ui/ClusterHeadline";
import ClipboardJS from "clipboard";
import {DateTime} from "luxon";
import {toast} from "react-toastify";
import PrimaryHelp from "../../../ui/PrimaryHelp";
import LoadingDialog from "../../../misc/LoadingDialog";

export default function ClusterApiKeyPage(props) {

  const { uuid } = useParams();

  const clusterService = useClusterService();
  const headline = useContext(HeadlineContext);
  const sidebar = useContext(SidebarContext);

  const [cluster, setCluster] = useState(null);
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const [revision, setRevision] = useState(DateTime.now());

  const [confirmed, setConfirmed] = useState(false);

  const copyApiKeyButtonRef = useRef(null);

  useBreadcrumbs(useContext(BreadcrumbContext), <BackToClustersLink />)

  useEffect(() => {
    sidebar.set(<ClusterDetailsSidebar cluster={null} clusterId={uuid} />);
    setCluster(null);
    clusterService.findOneCluster(uuid, setCluster);
    return () => {
      sidebar.set(null);
    };
  }, [uuid, revision]);

  useEffect(() => {
    if (cluster !== null) {
      headline.set(<ClusterHeadline cluster={cluster} />)
      sidebar.set(<ClusterDetailsSidebar cluster={cluster} clusterId={uuid} />);
    }

    return () => {
      headline.set(null);
      sidebar.set(null);
    };
  }, [cluster]);

  useEffect(() => {
    if (copyApiKeyButtonRef.current && cluster) {
      const clipboard = new ClipboardJS(copyApiKeyButtonRef.current);

      clipboard.on('success', function (e) {
        setCopiedToClipboard(true);
        e.clearSelection();
      });

      // Cleanup
      return () => {
        clipboard.destroy();
      };
    }
  }, [copyApiKeyButtonRef, cluster]);

  const cycleApiKey = () => {
    if (!confirm("Really cycle API key?")) {
      return;
    }

    clusterService.cycleClusterApiKey(cluster.uuid, () => {
      toast.success("API key cycled.");
      setCluster(null);
      setRevision(DateTime.now());
    })
  }

  const buttonText = () => {
    if (copiedToClipboard) {
      return (
          <React.Fragment>
            Copied to Clipboard <i className="bx bx-check"/>
          </React.Fragment>
      )
    } else {
      return "Copy to Clipboard"
    }
  }

  if (!cluster) {
    return <LoadingDialog />
  }

  return (
      <React.Fragment>
        <div className="row">
          <div className="col col-12">
            <div className="section">
              <h2>API Key</h2>

              <div className="api-key">
                <span id="api-key-value">{cluster.api_key}</span>

                <button className={"btn d-md-block d-none " + (copiedToClipboard ? "btn-success" : "btn-secondary")}
                        style={{float: "right"}}
                        ref={copyApiKeyButtonRef}
                        data-clipboard-target="#api-key-value">
                  {buttonText()}
                </button>

                <br style={{clear: "both"}}/>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col col-12">
            <div className="section">
              <h2>Cycle API Key</h2>

              <PrimaryHelp>
                You can cycle the API key of this cluster at any time. However, after changing the API key,
                you will need to update the API key in your nzyme cluster configuration to restore
                communication with <em>Connect</em>.
              </PrimaryHelp>

              <div className="row">
                <div className="col-8">
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="confirmed"
                           checked={confirmed} onChange={() => setConfirmed(!confirmed)}/>

                    <label className="form-check-label" htmlFor="confirmed">
                      I understand that I cannot revert to the current API key and that I have
                      to update the API key in my nzyme cluster configuration to restore
                      communication with <em>Connect</em> after cycling it.
                    </label>
                  </div>
                </div>
              </div>

              <button className="btn btn-danger mt-3" onClick={cycleApiKey} disabled={!confirmed}>Cycle API Key</button>
            </div>
          </div>
        </div>
      </React.Fragment>
  )

}