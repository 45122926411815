export const LoggedInRoutes = {
  CLUSTERS: {
    INDEX: "/",
    SHOW: uuid => `/clusters/show/${uuid}/overview`,
    NODES: uuid => `/clusters/show/${uuid}/nodes`,
    TAPS: uuid => `/clusters/show/${uuid}/taps`,
    HEALTH: uuid => `/clusters/show/${uuid}/health`,
    SUPPORT: uuid => `/clusters/show/${uuid}/support`,
    APIKEY: uuid => `/clusters/show/${uuid}/apikey`,
    DATA: {
      OUIS: uuid => `/clusters/show/${uuid}/data/ouis`,
      BLUETOOTH: uuid => `/clusters/show/${uuid}/data/bluetooth`,
      GEOIP: uuid => `/clusters/show/${uuid}/data/geoip`,
    },
    SETTINGS: {
      INDEX: uuid => `/clusters/${uuid}/settings`
    },
    MGMT: {
      CREATE: "/clusters/new",
    }
  },
  USER: {
    SETTINGS: "/user/settings"
  }
}