import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import useClusterService from "../../../../services/ClusterService";
import {BreadcrumbContext, HeadlineContext, SidebarContext} from "../../../LoggedInApp";
import useBreadcrumbs from "../../../ui/useBreadcrumbs";
import ClusterDetailsSidebar from "../ClusterDetailsSidebar";
import NodesTable from "./NodesTable";
import BackToClustersLink from "../../../ui/BackToClustersLink";
import ClusterHeadline from "../../../ui/ClusterHeadline";
import NeverReportedPage from "../NeverReportedPage";
import PrimaryHelp from "../../../ui/PrimaryHelp";
import LoadingDialog from "../../../misc/LoadingDialog";

export default function NodesPage() {

  const { uuid } = useParams();

  const clusterService = useClusterService();
  const headline = useContext(HeadlineContext);
  const sidebar = useContext(SidebarContext);

  const [cluster, setCluster] = useState(null);

  useBreadcrumbs(useContext(BreadcrumbContext), <BackToClustersLink />)

  useEffect(() => {
    sidebar.set(<ClusterDetailsSidebar cluster={null} clusterId={uuid} />);
    setCluster(null);
    clusterService.findOneCluster(uuid, setCluster);
    return () => {
      sidebar.set(null);
    };
  }, [uuid]);

  useEffect(() => {
    if (cluster !== null) {
      headline.set(<ClusterHeadline cluster={cluster} />)
      sidebar.set(<ClusterDetailsSidebar cluster={cluster} clusterId={uuid} />);
    }

    return () => {
      headline.set(null);
      sidebar.set(null);
    };
  }, [cluster]);

  if (!cluster) {
    return <LoadingDialog />
  }

  if (cluster.status === "INACTIVE") {
    return <NeverReportedPage />
  }

  return (
      <div className="row">
        <div className="col col-12">
          <div className="section">
            <h2>Nodes</h2>

            <PrimaryHelp>Showing all nodes active during the previous 24 hours.</PrimaryHelp>

            <NodesTable cluster={cluster} />
          </div>
        </div>
      </div>
  )

}