import React, {useState} from "react";
import ButtonSubmittingText from "../../../ui/ButtonSubmittingText";
export default function ClusterForm(props) {

  const onSubmit = props.onSubmit;
  const buttonText = props.buttonText;

  const [name, setName] = useState(props.name ? props.name : "")

  const [isSubmitting, setIsSubmitting] = useState(false);

  const submit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    onSubmit(name, () => {
      setIsSubmitting(false);
    });
  }

  const formIsReady = () => {
    return name && name.trim().length > 0
  }

  return (
      <form onSubmit={submit}>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">Cluster Name</label>
          <input type="text"
                 className="form-control"
                 id="name"
                 value={name}
                 onChange={(e) => setName(e.target.value)} />
          <div className="form-text">
            Choose a name for your cluster that will help you easily identify it among others. You can change this
            name later.
          </div>
        </div>

        <button type="submit" className="btn btn-primary" disabled={isSubmitting || !formIsReady()}>
          {isSubmitting ? <ButtonSubmittingText /> : buttonText }
        </button>
      </form>
  )

}