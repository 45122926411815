import React, {useEffect, useState} from "react";
import Plot from "react-plotly.js";

export default function ClusterTapThroughputChart(props) {

  const cluster = props.cluster;
  const height = props.height;
  const colors = props.colors;
  const xAxis = props.xAxis;
  const yAxis = props.yAxis;
  const margins = props.margins;

  const [throughputData, setThroughputData] = useState(null);
  const [chartIsEmpty, setChartIsEmpty] = useState(null);
  const [annotations, setAnnotations] = useState(null);

  useEffect(() => {
    const x = [];
    const y = [];

    let allEmpty = true;
    const fakeData = [];
    cluster.tap_throughput.forEach((t) => {
      if (t.value > 0) {
        allEmpty = false;
      } else {
        fakeData.push(getRandomInt(10000000, 100000000));
      }

      x.push(new Date(t.bucket));
      y.push(t.value / 1024 / 1024);
    })

    if (allEmpty) {
      setAnnotations([
        {
          x: 0.5,
          y: 0.5,
          xref: 'paper',
          yref: 'paper',
          text: 'No Throughput Data Available',
          showarrow: false,
          font: {
            size: 20,
            color: 'black',
            family: "Inconsolata"
          },
          align: 'center',
          valign: 'middle',
        },
      ]);
      setThroughputData({x: x, y: fakeData});
    } else {
      setThroughputData({x: x, y: y});
      setAnnotations([]);
    }

    setChartIsEmpty(allEmpty);
  }, [cluster]);

  if (throughputData == null || chartIsEmpty == null || annotations == null) {
    return null;
  }

  return <Plot
          style={{ width: "100%", height: height }}
          data={[
            {
              x: throughputData.x,
              y: throughputData.y,
              type: "bar",
              marker: {size: 3, color: chartIsEmpty ? colors.barEmptyChart : colors.bar},
            }
          ]}
          layout={{
            margin: margins,
            showlegend: false,
            dragmode: false,
            clickmode: "none",
            hovermode: chartIsEmpty ? false : "x+y",
            xaxis: {
              fixedrange: true,
              linecolor: xAxis.lineColor,
              linewidth: xAxis.lineWidth,
              gridcolor: xAxis.gridColor,
              zeroline: false,
              tickfont: {
                family: "Inconsolata, monospace",
                size: 12,
                color: '#7c7c7c'
              }
            },
            yaxis: {
              ticksuffix: chartIsEmpty ? null : " Mbit/sec",
              tickformat: chartIsEmpty ? null : ".2~f",
              fixedrange: true,
              linecolor: yAxis.lineColor,
              linewidth: yAxis.lineWidth,
              gridcolor: yAxis.gridColor,
              zeroline: false,
              tickfont: {
                family: "Inconsolata, monospace",
                size: 12,
                color: '#7c7c7c'
              }
            },
            annotations: annotations
          }}
          useResizeHandler
          config={{
            displayModeBar: false,
            autosize: true,
            responsive: true
          }}
      />

}

function getRandomInt(min, max) {
  const minCeiled = Math.ceil(min);
  const maxFloored = Math.floor(max);
  return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled);
}
