import Store from "./Store";
import {toast} from "react-toastify";
import {useAuth0} from "@auth0/auth0-react";

const axios = require('axios')

const useApiClient = () => {

  const { logout } = useAuth0();

  const getAuthHeaders = () => {
    const headers = {}
    const apiToken = Store.get("api_token");

    // Add API token authorization if we have one.
    if (apiToken) {
      headers.Authorization = 'Bearer ' + apiToken
    }

    return headers
  }

  const buildUri = (uri) => {
    let stableRoot = process.env.CONNECT_API_URI;
    if (process.env.CONNECT_API_URI.slice(-1) !== '/') {
      stableRoot = process.env.CONNECT_API_URI + '/'
    }

    let stableUri = uri
    if (uri.charAt(0) === '/') {
      stableUri = uri.substr(1)
    }

    return stableRoot + stableUri
  }

  const get = (uri, params, successCallback, errorCallback = undefined) => {
    axios.get(buildUri(uri), { params: params, headers: getAuthHeaders() })
        .then(function (response) {
          successCallback(response)
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 401) {
              /*
               * Authentication failed. Most likely expired token.
               *
               * Our "absolute lifetime" of the refresh tokens are only 12 hours so this should never happen because
               * a user would be logged out of the app and then acquire a new JWT on the next login, but we still
               * gracefully log out the user.
               */

              console.log("API token expired. Destroying session.")
              logout({logoutParams: {returnTo: process.env.AUTH0_LOGOUT_CALLBACK_URI}})
            }

            if (errorCallback) {
              errorCallback(error)
            } else {
              toast.error("That didn't work! Please try again.")
            }
          } else {
            toast.error("That didn't work! Please try again.")
          }
        })
  }

  const post = (uri, data, successCallback, errorCallback = undefined) => {
    axios.post(buildUri(uri), data, { headers: getAuthHeaders() })
        .then(function (response) {
          successCallback(response)
        })
        .catch(function (error) {
          if (errorCallback) {
            errorCallback(error)
            return;
          }

          toast.error("That didn't work! Please try again.")
        })
  }

  const put = (uri, data, successCallback, errorCallback = undefined) => {
    axios.put(buildUri(uri), data, { headers: getAuthHeaders() })
        .then(function (response) {
          successCallback(response)
        })
        .catch(function (error) {
          if (errorCallback) {
            errorCallback(error)
          } else {
            toast.error("That didn't work! Please try again.")
          }
        })
  }

  const del = (uri, successCallback, errorCallback = undefined) => {
    axios.delete(buildUri(uri), { headers: getAuthHeaders() })
        .then(function (response) {
          successCallback(response)
        })
        .catch(function (error) {
          if (errorCallback) {
            errorCallback(error)
          } else {
            toast.error("That didn't work! Please try again.")
          }
        })
  }

  return {
    get, post, put, del
  };

}

export default useApiClient;