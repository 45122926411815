import React from "react";

export default function AlertWarning(props) {

  const hideIcon = props.hideIcon;

  return (
      <div className="alert alert-warning">
        { hideIcon ? null : <i className="bx bx-error alert-icon"></i>}  {props.children}
      </div>
  )

}