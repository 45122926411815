import React from "react";

export default function ClusterHealthStatus(props) {

  const cluster = props.cluster;
  const showGreen = props.showGreen;

  switch (cluster.status) {
    case "ONLINE":
      switch (cluster.health_indicator_summary) {
        case "GREEN":
          if (showGreen) {
            return <i className="bx bx-check-circle cluster-health color-success" title="Cluster is online and no issues were detected."/>
          } else {
            return null;
          }
        case "ORANGE":
          return <i className="bx bx-error cluster-health color-warning" title="Cluster is online but it reports health warnings."></i>
        case "RED":
          return <i className="bx bx-error cluster-health color-danger" title="Cluster is online but it reports critical health issues."></i>
      }
    case "OFFLINE":
      return <i className="bx bx-error cluster-health color-danger" title="Cluster is not reporting data."></i>
    case "INACTIVE":
      return <i className="bx bx-error cluster-health color-inactive" title="Cluster has never reported data."/>
  }

}